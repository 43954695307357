<template>
  <div>
    <b-alert show variant="light" class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="text-muted mt-3 font-weight-bold font">
        In this page you will find all your devices in the next table
      </div>
    </b-alert>

    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">All my devices</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="d-flex justify-content-center mb-3" v-if="list == null">
          <b-spinner variant="primary"> </b-spinner>
        </div>
        <b-card v-if="list != null && list.length == 0" border-variant="warning" header="Heads up!"
          header-bg-variant="transparent" align="center">
          <b-card-text>Looks like there are no devices registered in your account
            yet.</b-card-text>
        </b-card>
        <div class="table-responsive" v-if="list != null && list.length > 0">
          <table class="table table-head-custom table-vertical-center" id="kt_advance_table_widget_1">
            <thead>
              <tr class="text-left">
                <th class="pl-0" style="width: 20px"></th>
                <th class="pr-0" style="width: 70px"></th>
                <th class="pr-0" style="min-width: 75px">Name</th>
                <th style="min-width: 0px">Location</th>
                <th style="min-width: 150px">Description</th>
                <th style="min-width: 150px">Creation Date</th>
                <th style="min-width: 120px">Token</th>
                <th class="pr-0 text-right" style="min-width: 70px">action</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in list">
                <tr v-bind:key="i">
                  <td class="pl-0">
                    <label class="h2 mb-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                      <b-icon icon="wifi" variant="success" v-if="item.connection_type == 1" v-on="on"></b-icon>
                      <b-icon icon="wifi-off" variant="danger" v-on="on" v-else-if="item.connection_type == 0"></b-icon>
                      <b-icon icon="exclamation-circle" variant="warning" v-on="on"  v-else></b-icon>
                      <span></span>
                    </template>
                    <span>
                      {{ getConnectionTitleType(item.connection_type, item.updated_at) }}
                    </span>
                      </v-tooltip>
                    </label>
                  </td>

                  <td class="pr-0">
                    <div class="symbol symbol-50 symbol-light mt-1">
                      <span class="symbol-label">
                        <img :src="getIconPath(item.version)" class="h-75 align-self-end" alt="" />
                      </span>
                    </div>
                  </td>

                  <td class="pl-0">
                    <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg" font
                      style="text-transform: capitalize" v-on:click.prevent="goToDetails(item.version, item.token)">{{
                        item.object }}</a>
                  </td>

                  <td>
                    <span class="text-muted font-weight-bold text-muted d-block">{{ item.location }}</span>
                  </td>

                  <td>
                    <span class="text-muted font-weight-bold text-muted d-block">{{ item.description }}</span>
                  </td>

                  <td>
                    <span class="text-muted font-weight-bold text-muted d-block">{{ item.created_at | formatDate }}</span>
                  </td>

                  <td>
                    <span class="text-muted font-weight-bold text-muted d-block">{{ item.token }}</span>
                  </td>

                  <td class="pr-0 text-right">
                    <!-- <a
                      href="#"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">

                        <inline-svg
                          src="media/svg/icons/General/Settings-1.svg"
                        />

                      </span>
                    </a> -->
                    <a href="#" v-on:click.prevent="goToDetails(item.version, item.token)"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" v-b-tooltip.hover title="Details">
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                        <inline-svg src="media/svg/icons/Communication/Write.svg" />
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                    <a href="#" v-on:click.prevent="removeDevicePrompt(item.token)"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm" v-b-tooltip.hover title="Remove device">
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg src="media/svg/icons/General/Trash.svg" />
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Example-->
      </div>
    </div>
    <b-modal ref="remove-device-modal" hide-footer title="Remove device">
      <div class="d-block text-center">
        <h3>You are about to unlink this device from your account</h3>
      </div>
      <b-button ref="confirm-remove-btn" class="mt-3" variant="outline-danger" block @click="removeDeviceConfirmed">Yes,
        unlink from account</b-button>
      <b-button ref="keep-remove-btn" class="mt-2" variant="outline-warning" block
        @click="closeRemovePrompt">Keep</b-button>
    </b-modal>
  </div>
</template>

<style>
.checkbox>input:checked~span {
  background-color: #1bc5bd;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_DEVICES } from "@/core/services/store/devices.module";
import { ICONS_TECHNICAL } from "@/core/enums/icons.enum";
import { DELETE_DEVICE } from "@/core/services/store/devices.module";

export default {
  data() {
    return {
      getIconPath(deviceVersion) {
        return ICONS_TECHNICAL[deviceVersion];
      },
      getConnectionTitleType(type, date) {
        if (type == 0 || type == undefined) {
          return "Offline since " + this.formatDate(date);
        } else if (type == 1) {
          return "Online since " + this.formatDate(date);
        } else {
          return "No connection data yet, last seen " + this.formatDate(date);
        }
      },
      list: null,
      lastSelectedDevice: "",
    };
  },
  
  mounted() {
    this.$store.dispatch(GET_DEVICES).then((inlist) => {
      this.list = inlist;
    });
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Alerts" },
    ]);
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) {
        return 'N/A';
      }
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    let date;
    try {
      date = new Date(dateString);
      if (isNaN(date)) {
        throw new Error("Invalid date");
      }
      return new Intl.DateTimeFormat(undefined, options).format(date);
    } catch (err) {
      console.error("Error formatting date:", err);
      return null;
    }
  },
    removeDevicePrompt(token) {
      this.lastSelectedDevice = token;
      this.$refs["remove-device-modal"].show();
    },
    removeDeviceConfirmed() {
      this.$refs["keep-remove-btn"].disabled = true;
      this.$refs["confirm-remove-btn"].disabled = true;
      const deviceToRemove = this.lastSelectedDevice;
      this.$store
        .dispatch(DELETE_DEVICE, { deviceToRemove })
        // go to which page after successfully login
        .then((result) => {
          // download devices list
          if (result.result == "success") {
            this.$bvToast.toast("The device has been removed", {
              title: `Result`,
              variant: "success",
              solid: true,
            });
            this.$store.dispatch(GET_DEVICES).then((inlist) => {
              this.list = inlist;
            });
          } else {
            this.$bvToast.toast("There was a problem removing the device", {
              title: `Result`,
              variant: "danger",
              solid: true,
            });
          }
          this.$refs["remove-device-modal"].hide();
        })
        .catch(() => {
          this.$bvToast.toast("There was a problem removing the device", {
            title: `Result`,
            variant: "danger",
            solid: true,
          });
        });
    },
    closeRemovePrompt() {
      this.$refs["remove-device-modal"].hide();
    },
    goToDetails(deviceVersion, token) {
      console.log("goToDetails", deviceVersion, token);
      switch (deviceVersion) {
        case null:
          this.$router.push({
            name: "device-generic",
            params: {
              token: token,
            },
          });
          break;
        case "camv1":
          this.$router.push({
            name: "device-camera",
            params: {
              token: token,
            },
          });
          break;
        case "sfir":
        case "sfir_alpha0":
          this.$router.push({
            name: "device-sfir",
            params: {
              token: token,
            },
          });
          break;
        case "thermostatv1":
          this.$router.push({
            name: "device-thermo",
            params: {
              token: token,
            },
          });
          break;
        case "doorv1":
          this.$router.push({
            name: "device-doorlock",
            params: {
              token: token,
            },
          });
          break;
        case "seismov1":
          this.$router.push({
            name: "device-seismograph",
            params: {
              token: token,
            },
          });
          break;
        case "stonev1":
        case "suitchv3":
          this.$router.push({
            name: "device-stone",
            params: {
              token: token,
            },
          });
          break;
        case "trackerv1":
          this.$router.push({
            name: "device-tracker",
            params: {
              token: token,
            },
          });
          break;
        case "soilblessv1":
        case "templessv1":
          this.$router.push({
            name: "device-soilv1",
            params: {
              token: token,
            },
          });
          break;
        case "enermonv1":
          this.$router.push({
            name: "device-enermonv1",
            params: {
              token: token,
            },
          });
          break;
      }
    },
  },
};
</script>
